import { SET_CONTACT_REQUESTS } from '../constants'

const contactRequests = []
export const contactRequestReducer = (state = contactRequests, action) => {
  switch (action.type) {
    case SET_CONTACT_REQUESTS:
      return action.data.data
    default:
      return state
  }
}
