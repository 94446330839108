/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux'
import { roleReducer } from './allRedudcers/roleReducer'
import { userReducer } from './allRedudcers/userReducer'
import { contactRequestReducer } from './allRedudcers/contactRequestReducer'

export default combineReducers({
  roleReducer,
  userReducer,
  contactRequestReducer
})
