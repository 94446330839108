import { put, takeEvery } from 'redux-saga/effects'
import {
  ROLES,
  SET_ROLES,
  USERS,
  SET_USERS,
  CONTACT_REQUESTS,
  SET_CONTACT_REQUESTS,
} from './constants'
import globalVariable from 'src/globalVariable'

function* rolesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/roles/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_ROLES, data })
  } catch (error) {
    // console.log(error.message)
  }
}
function* usersList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/user/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_USERS, data })
  } catch (error) {
    // console.log(error.message)
  }
}

function* contactRequestList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/contact_request/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_CONTACT_REQUESTS, data })
  } catch (error) {
    // console.log(error.message)
  }
}

function* SagaData() {
  yield takeEvery(ROLES, rolesList)
  yield takeEvery(USERS, usersList)
  yield takeEvery(CONTACT_REQUESTS, contactRequestList)
}

export default SagaData
