// ------- roles --------
export const ROLES = 'roles'
export const SET_ROLES = 'set_roles'
// ------- roles --------

// ------- users --------
export const USERS = 'users'
export const SET_USERS = 'set_users'
// ------- users --------

// ------- users --------
export const CONTACT_REQUESTS = 'contact_requests'
export const SET_CONTACT_REQUESTS = 'set_contact_requests'
// ------- users --------
